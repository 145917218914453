import { useEffect, useState, useRef, useMemo } from "react";
import InputMask from "react-input-mask";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, getUIFlags } from "../../reducers/auth";
import { resetPassword } from "../../reducers/resetPassword";
import { forgetPwd } from "../../reducers/forgotPassword";
import ResetPassword from "./ResetPassword";
import ForgetPassword from "./ForgetPassword";
import ForgotTempPassword from "./forgotTempPassword";
import { successToast, errorToast } from "../toast/toast"
import "react-toastify/dist/ReactToastify.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { MotionText } from "../motion/MotionText";
import 'react-datepicker/dist/react-datepicker.css';
import { generateOtp } from "../../reducers/validateOtp";
import { setShowPahrmacyMap, setShowResetPasswordModal } from "../../reducers/medication";
import { usernameValidation } from "../Fields/Username";
import {
  setIP, setAuth,
  setIsCalledAlready,
  setHasDob, setHasFirstName, setHasPhoneNumber,
  setResetModalIsOpen,
  setAnimatedLoading
} from "../../reducers/global";
import { httpClientJava } from "../../environment";
import DateOfBirth from "../Fields/DateOfBirth";
import { StringToHTML } from "../../utilities/StringToHtml";
import { getDomainName } from "../../utilities/useGetDomain";
import TickCross from "../../utilities/TickCross";
import { ariaAnnounce } from "../../utilities/useAnnounceText";
import LoadingComponent from "../LoadingComponent";

import { useGetUser } from "../../utilities/useGetUser";
import logo from "../../assets/images/logo.png";

const Login = () => {

  // Determine if the environment is production based on a custom environment variable
  const userDetail = useGetUser();  // Custom hook to retrieve user details
  const [isOpenForgotPwdModal, setIsOpenForgotPwdModal] = useState(false);  // State to manage the visibility of the "Forgot Password" modal
  const [resetPwd, setResetPasswd] = useState("");  // State to hold the entered password for reset functionality
  const [passType, setPassType] = useState("password"); // State to toggle the visibility of the password input field (show/hide password)
  const [loading, setLoading] = useState(false); // State to manage the loading state for asynchronous operations (e.g., API calls)

  const [submitResetPasswordData, setSubmitResetPasswordData] = useState(""); // State to store the data when the user submits a password reset request
  const [submitForgotPasswordData, setSubmitForgotPasswordData] = useState(""); // State to store the data when the user submits a "Forgot Password" request

  const navigate = useNavigate();// Hook to navigate programmatically between routes
  const dispatch = useDispatch();// Hook to dispatch actions to Redux

  // Refs to access DOM elements directly (password, username, and date of birth inputs)
  const passwordRef = useRef(null);
  const usernameRef = useRef(null);
  const dobRef = useRef(null);

  // States to handle validation error messages for month, year, and day
  const [errorMonth, setErrorMonth] = useState("121");
  const [errorYear, setErrorYear] = useState("121");
  const [errorDay, setErrorDay] = useState("121");

  const { domainName, defaultLogo, altText } = getDomainName(); // Destructuring properties (domain name, logo, and alt text) from a helper function

  var numberPattern = /\d+/g; // Regular expression to match numeric patterns

  // Memoized value to check if the user is a new user based on the presence of a query parameter (?p=)
  const isNewUser = useMemo(() => {
    return window.location.href.includes("?p=");
  }, [window.location.href]);

  // Redux selectors to access values from the global state
  const isCalledAlready = useSelector((state) => state.global.isCalledAlready);
  const hasDob = useSelector((state) => state.global.hasDob);
  const hasFirstName = useSelector((state) => state.global.hasFirstName);
  const hasPhoneNumber = useSelector((state) => state.global.hasPhoneNumber);
  const resetModalIsOpen = useSelector((state) => state.global.resetModalIsOpen);

  useEffect(() => {
    ariaAnnounce(text); // useEffect hook to announce the text using ARIA for accessibility, runs once on component mount
  }, [])

  // Function to get a parameter from the current URL based on its name (e.g., "?p=")
  const getParamFromURL = (paramName) => {
    const url = window.location.href;
    const paramStart = url.indexOf(`?${paramName}=`);
    if (paramStart === -1) {
      return null; // Parameter not found in the URL
    }

    const paramEnd = url.indexOf('&', paramStart);
    const valueStart = paramStart + paramName.length + 2; // Add 2 for the "=" character

    if (paramEnd === -1) {
      return url.substring(valueStart);
    } else {
      return url.substring(valueStart, paramEnd);
    }
  }

  var patientHash = getParamFromURL("p"); // Retrieve the "p" parameter (e.g., patient hash) from the URL

  // Define a validation schema for the form using Yup
  const validationSchema = Yup.object().shape({
    username: usernameValidation,
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .max(40, "Password must not exceed 40 characters"),
  });

  // Define a different validation schema if the user has a date of birth (DOB) or first name, depending on global state
  const validationSchemaNewUser = hasDob ? Yup.object().shape({
    dob: Yup.string().required("Required"),
  }) : hasFirstName ? Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required")
  }) : hasPhoneNumber ? Yup.object().shape({
    username: usernameValidation,
  }) : null;

  const showResetPasswordModal = useSelector((state) => state.medication.showResetPasswordModal); // Redux selector to check if the reset password modal is being shown

  // useEffect hook to handle new users, clear storage, and make necessary API calls on component mount or when `isNewUser` changes
  useEffect(() => {
    if (isNewUser && !isCalledAlready) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      setLoading(true);
      dispatch(getUIFlags())
        .unwrap()
        .then((data) => {
          dispatch(setIsCalledAlready(true));
          if (data.status == "OK") {
            if (data?.data.hasDob === 1) {
              dispatch(setHasDob(true))
            }
            else if (data.data.hasDob === 0) {
              dispatch(setHasFirstName(true))
            }
          }
          else if (data.status == "CONTINUE") {
            const body = {
              urlHash: patientHash,
              domainName: domainName
            }
            dispatch(login(body))
              .unwrap()
              .then((response) => {
                if (response.data) {
                  localStorage.setItem("user", JSON.stringify(response?.data));
                  dispatch(setShowResetPasswordModal(true));
                }
                else {
                  errorToast(response.message);
                }
              })
              .catch((err) => {
                formik.setFieldValue("password", "");
                formik.setTouched('password', false);
                console.log({ err })
              })
          }
          else if (data.status == "IM_USED") {
            window.location.replace("/#/");
          }
          else {
            dispatch(setHasPhoneNumber(true));
          }
          setLoading(false);
        }).catch((error) => {
          setLoading(false);
        }).finally(() => {
          setLoading(false);
        })
    }
  }, [isNewUser])

  const ctrls = useAnimation(); // Hook to handle animations

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  }); // Hook to detect if an element is in view, triggering animations based on its visibility

  useEffect(() => {
    if (inView) {
      ctrls.start("visible");
    }
    if (!inView) {
      ctrls.start("hidden");
    }
  }, [ctrls, inView]);

  const wordAnimation = {
    hidden: {},
    visible: {},
  };

  const characterAnimation = {
    hidden: {
      opacity: 0,
      y: `0.25em`,
    },
    visible: {
      opacity: 1,
      y: `0em`,
      transition: {
        duration: 1,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
  };
  // Choose the correct validation schema based on whether the user is new
  const formValidationSchema = isNewUser ? validationSchemaNewUser : validationSchema;

  // Initialize formik for form management with initial values, validation, and submission logic
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      dob: "",
      firstName: "",
      lastName: ""
    },
    validationSchema: formValidationSchema, // Use the appropriate validation schema
    onSubmit: (data) => {
      if (loading) {
        return; // Prevent form submission while loading
      }
      // Handle new users who do not have a DOB or first name yet
      if (isNewUser && !hasDob && !hasFirstName && !hasPhoneNumber) {
        const url = `patient/getUIFlags?urlHash=${patientHash}`;
        setLoading(true);
        // Make a GET request to retrieve flags
        httpClientJava.get(url)
          .then(({ data }) => {
            if (data.status == "OK") {
              if (data?.data.hasDob === 1) {
                setHasDob(true); // Set flag indicating the user has a DOB
              }
              else if (data.data.hasDob === 0) {
                setHasFirstName(true); // Set flag indicating the user has a firstName
              }
            }
            // If the status is CONTINUE, log the user in
            if (data.status == "CONTINUE") {
              const body = {
                urlHash: patientHash,
                domainName: domainName
              }
              dispatch(login(body))
                .unwrap()
                .then((response) => {
                  if (response.data) {
                    localStorage.setItem("user", JSON.stringify(response?.data));
                    dispatch(setShowResetPasswordModal(true));
                  }
                  else {
                    errorToast(<StringToHTML htmlString={response?.message} />);
                  }
                })
                .catch((err) => {
                  formik.setFieldValue("password", "");
                  formik.setTouched('password', false);
                  console.log({ err })
                })
            }
            else {
              errorToast(<StringToHTML htmlString={data?.message} />);
            }
            setLoading(false);
          }).catch((error) => {
            console.log(error);
            setLoading(false);
          }).finally(() => {
            setLoading(false);
          })
        return
      }
      dispatch(setAnimatedLoading(true)); // Start animated loading indicator
      let body = {}
      if (!isNewUser) {
        body =
        {
          username: data.username.match(numberPattern).join(""),
          password: data?.password,
          domainName: domainName
        };
      }
      else if (isNewUser && hasDob) {
        body = {
          dob: String(data?.dob.getFullYear()) + String(data?.dob.getMonth() + 1).padStart(2, '0') + String(data?.dob.getDate()).padStart(2, '0'),
          urlHash: patientHash,
          domainName: domainName
        }
      }
      else if (isNewUser && !hasDob && hasFirstName) {
        body = {
          firstName: data.firstName,
          lastName: data.lastName,
          urlHash: patientHash,
          domainName: domainName
        }
      }
      else if (isNewUser && !hasDob && !hasFirstName && hasPhoneNumber) {
        body = {
          number: data.username.match(numberPattern).join(""),
        };
        setSubmitForgotPasswordData(body);
        dispatch(setAnimatedLoading(false));
        return;
      }
      dispatch(login(body))
        .unwrap()
        .then((response) => {
          if (response.data != undefined)
            localStorage.setItem("user", JSON.stringify(response?.data));
          if (response?.data?.enrollmentStatus === "Unverified") {
            localStorage.setItem("user", JSON.stringify(response?.data));
            dispatch(generateOtp(body))
              .unwrap()
              .then((response) => {
              })
            localStorage.setItem("userBody", JSON.stringify(body));
            navigate("/otpVerify");
            dispatch(setAnimatedLoading(false));
            return;
          }
          if (response?.statusCode >= 200 && response?.statusCode <= 299 || response?.statusCode === 307) {
            dispatch(setAuth(response?.data))
            dispatch(setIP(response?.data.proxyIpAddress))
            if (response?.data?.enrollmentStatus === "Pending") {
              dispatch(setShowResetPasswordModal(true))
              dispatch(setAnimatedLoading(false))
            } else if (response?.data?.enrollmentStatus === "Unverified") {
              navigate("/otpVerify");
              setLoading(false);
            } else {
              let condition = response?.data?.formSubmitted;
              if (condition) {
                if (response?.data?.hasPharmacy) {
                  dispatch(setShowPahrmacyMap(false));
                  window.location.replace("/#/medications")
                }
                else {
                  dispatch(setShowPahrmacyMap(true));
                  window.location.replace("/#/medications")
                }
              } else {
                window.location.replace("/#/insurance");
                dispatch(setAnimatedLoading(false))
              }
            }
          } else {
            errorToast(<StringToHTML htmlString={response?.message} />);
            formik.setFieldValue("password", "");
            dispatch(setAnimatedLoading(false))
            formik.setTouched('password', true);
            usernameRef.current && usernameRef.current.focus();
            passwordRef.current && passwordRef.current.focus();
          }
        })
        .catch((e) => {
          dispatch(setAnimatedLoading(false))
        });
    },
  });

  // Handle forgot password form submission
  useEffect(() => {
    if (submitForgotPasswordData?.number) {
      setLoading(true);
      dispatch(
        forgetPwd(
          submitForgotPasswordData?.number
        )
      )
        .unwrap()
        .then((response) => {
          setLoading(false);
          if (response?.status === "OK") {
            localStorage.setItem("user", JSON.stringify(response?.data));
            setIsOpenForgotPwdModal(false);
            dispatch(setResetModalIsOpen(true))
            dispatch(setIP(response?.data.proxyIpAddress))
            successToast(response?.message);
          }
          else if (response?.status === "BANDWIDTH_LIMIT_EXCEEDED") {
            errorToast(<StringToHTML htmlString={"An OTP code was already sent to your device. Please wait 10 minutes and if the code is still not received email us at: <a href='mailto:support@rxlink.com'>support@rxlink.com</a>"} />);
          }
          else {
            errorToast(<StringToHTML htmlString={response?.message} />);
            setLoading(false);
          }
        });
    }
  }, [submitForgotPasswordData]);

  // Handle reset password form submission
  useEffect(() => {
    if (submitResetPasswordData?.password) {
      let body = {
        appUserSeqNo: userDetail?.appUserSeqNo,
        username: userDetail?.username,
        password: submitResetPasswordData?.password,
        forgetPassword: submitResetPasswordData?.tempPassword ? true : false,
        tempPassword: submitResetPasswordData?.tempPassword
          ? submitResetPasswordData?.tempPassword
          : null,
      };
      setResetPasswd(body);
    }
  }, [submitResetPasswordData]);

  useEffect(() => {
    if (resetPwd?.password) {
      setLoading(true);
      dispatch(resetPassword(resetPwd))
        .unwrap()
        .then((response) => {
          setLoading(false);
          if (response?.statusCode >= 200 && response?.statusCode <= 299) {
            localStorage.setItem("user", JSON.stringify({ ...userDetail, token: response.data.token, refreshToken: response.data.refreshToken, dob: response.data.dob }));
            dispatch(setShowResetPasswordModal(false));
            successToast(response?.message);
            dispatch(setResetModalIsOpen(false))
            let condition = userDetail?.formSubmitted;
            if (condition) {
              window.location.replace("/#/medications")
            } else {
              window.location.replace("/#/insurance")
            }
          } else {
            errorToast(<StringToHTML htmlString={response?.message} />);
          }
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [resetPwd]);

  const signUp = (e) => {
    navigate("/signUp");
  };

  const { isSubmitting, isValidating } = formik;
  const text = 'Personalized medication savings';
  useEffect(() => {
    if (isSubmitting) {
      if (Object.keys(formik.errors).length > 0) {
        handleInputFocus();
      }
    }
  }, [isSubmitting, isValidating]);

  const handleInputFocus = () => {
    let firstErrorField = Object.keys(formik?.errors)?.find(
      (fieldName) => formik.touched[fieldName]
    );
    if (firstErrorField) {
      switch (firstErrorField) {
        case "firstName":
          window.document.getElementById("userFirstNameInput").focus();
          ariaAnnounce(formik.errors.firstName);
          break;
        case "lastName":
          window.document.getElementById("userLastNameInput").focus();
          ariaAnnounce(formik.errors.lastName);
          break;
        case "password":
          window.document.getElementById("loginPasswordInput").focus();
          ariaAnnounce(formik.errors.password);
          break;
        case "username":
          window.document.getElementById("loginPhoneInput").focus();
          ariaAnnounce(formik.errors.username);
          break;
        case "dob":
          if (errorMonth || errorMonth == "121") {
            window.document.getElementById("loginmonthInput")?.focus();
            break;
          }
          if (errorDay || errorDay == "121") {
            window.document.getElementById("logindayInput")?.focus();
            break;
          }
          if (errorYear || errorYear == "121") {
            window.document.getElementById("loginyearInput")?.focus();
            break;
          }
          break;
        default:
          break;
      }
    }
  }

  const InputBag = () => {
    // Check if the user is new and has a date of birth (DOB)
    if (isNewUser && hasDob) {
      return (
        <>
          <p style={{ fontStyle: "italic", fontSize: 11, textAlign: "left" }}>Please enter your Date of Birth</p>
          <div style={{ width: '350px' }} className="forget_input_eye">
            <DateOfBirth formik={formik} fieldName={'dob'}
              enabled={true}
              id="login"
              ariaLabel="login"
              inputRef={dobRef}
              errorMonth={errorMonth}
              errorYear={errorYear}
              errorDay={errorDay}
              setErrorDay={setErrorDay}
              setErrorMonth={setErrorMonth}
              setErrorYear={setErrorYear}
              deskClass={"error-circle"}
              mobileClass={"error-circle"}
            />
          </div>

        </>
      )
    }
    // Check if the user is new but does not have a DOB, and has a first name
    else if (isNewUser && !hasDob && hasFirstName) {
      return (
        <>
          <div className="row g-0">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="mb-1 forget_input_eye form-group">
                <input
                  name="firstName"
                  type="text"
                  className={
                    "form-control custom-form-control login_input" +
                    (formik.errors.firstName && formik.touched.firstName
                      ? " is-invalid"
                      : "")
                  }
                  id="userFirstNameInput"
                  placeholder="Enter your first name"
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                  aria-label="First Name"
                  onFocus={() => formik.errors.firstName && ariaAnnounce("Required")}
                />
                {formik.errors.firstName && formik.touched.firstName && <div className="invalid-feedback"
                  id="firstNameError" aria-live="off">
                  {formik.errors.firstName && formik.touched.firstName
                    ? formik.errors.firstName
                    : null}
                </div>}
                {TickCross(formik, "firstName", "error-circle")} {/* Display tick or cross icon based on validation */}
              </div>
            </div>
          </div>
          <div className="row g-0">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="mb-1 forget_input_eye form-group">
                <input
                  name="lastName"
                  type="text"
                  className={
                    "form-control custom-form-control login_input" +
                    (formik.errors.lastName && formik.touched.lastName
                      ? " is-invalid"
                      : "")
                  }
                  id="userLastNameInput"
                  placeholder="Enter your last name"
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                  aria-label="Last Name"
                  onFocus={() => formik.errors.lastName && ariaAnnounce("Required")}
                />
                {formik.errors.lastName && formik.touched.lastName &&
                  <div className="invalid-feedback"
                    id="lastNameError" aria-live="off">
                    {formik.errors.lastName && formik.touched.lastName
                      ? formik.errors.lastName
                      : null}
                  </div>}
                {TickCross(formik, "lastName", "error-circle")} {/* Display tick or cross icon based on validation */}
              </div>
            </div>
          </div>
        </>
      )
    }
    else if (isNewUser && !hasDob && !hasFirstName && hasPhoneNumber) {
      return (
        <div className="forget_input_eye">
          <InputMask
            name='username'
            className={`form-control  custom-form-control ${true && "disabled-input"}` +
              (formik.errors.username && formik.touched.username
                ? " is-invalid"
                : "")}
            mask="(999) 999-9999"
            maskChar={null}
            placeholder="Mobile phone number"
            onChange={formik.handleChange}
            value={formik.values.username}
            aria-label="Mobile Phone Number"
            inputRef={usernameRef}
            id="loginPhoneInput"
          >
          </InputMask>
          {formik.errors.username && formik.touched.username && <div className="invalid-feedback" aria-live="off" id="usernameError">
            {formik.errors.username && formik.touched.username
              ? formik.errors.username
              : null}
          </div>}
          {TickCross(formik, "username", "error-circle")}
        </div>
      )
    }
  }

  const changePassType = () => {
    if (passType == "password") {
      setPassType("text")
    }
    else {
      setPassType("password")
    }
  }

  return (
    <>
      {loading ?
        <LoadingComponent />  // Show loading component when loading is true
        : <>
          <motion.section
            initial={{ y: "-100vh" }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="login_section" >
              {/* Hidden heading for screen readers */}
              <div id="headingToAnnounce" style={{ position: 'absolute', left: '-9999px', zIndex: '-1' }} />
              {/* Form for user login */}
              <form onSubmit={formik.handleSubmit} id="userLoginForm">
                <div className="row g-0">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="login_section_inner">
                      <div className="row g-0">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <div className="logo">
                            <motion.img
                              src={defaultLogo}
                              className="img-fluid"
                              alt={altText}
                              initial={{ y: -100 }}
                              animate={{ y: 0 }}
                              transition={{ duration: 0.5, delay: 0.4 }}
                            />
                          </div>
                          <MotionText text={text}
                            ctrls={ctrls}
                            wordAnimation={wordAnimation}
                            characterAnimation={characterAnimation}
                            cref={ref}
                            id="headingToAnnounceId"
                          />
                        </div>
                      </div>
                      {/* Conditional rendering for input fields if the user is not new */}
                      {!isNewUser &&
                        <div className="forget_input_eye">
                          <InputMask
                            name='username'
                            className={`form-control  custom-form-control ${true && "disabled-input"}` +
                              (formik.errors.username && formik.touched.username
                                ? " is-invalid"
                                : "")}
                            mask="(999) 999-9999"
                            maskChar={null}
                            placeholder="Mobile phone number"
                            onChange={formik.handleChange}
                            value={formik.values.username}
                            aria-label="Mobile Phone Number"
                            inputRef={usernameRef}
                            id="loginPhoneInput"
                          >
                          </InputMask>
                          {formik.errors.username && formik.touched.username && <div className="invalid-feedback" aria-live="off" id="usernameError">
                            {formik.errors.username && formik.touched.username
                              ? formik.errors.username
                              : null}
                          </div>}
                          {TickCross(formik, "username", "error-circle")}
                        </div>
                      }
                      {/* Password input (for non-new users) */}
                      {!isNewUser ? <div className="row g-0">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <div className="forget_input_eye mb-1  form-group">
                            <input
                              name="password"
                              type={passType}
                              className={
                                "form-control custom-form-control" +
                                (formik.errors.password &&
                                  formik.touched.password
                                  ? " is-invalid"
                                  : "")
                              }
                              id="loginPasswordInput"
                              placeholder="Enter your password"
                              onChange={formik.handleChange}
                              value={formik.values.password}
                              aria-label="Password"
                              ref={passwordRef}
                              onFocus={() => ariaAnnounce(formik.errors.password)}
                            />
                            {formik.errors.password && formik.touched.password && <div id="loginPass-error" className="invalid-feedback"
                            >
                              {formik.errors.password && formik.touched.password
                                ? formik.errors.password
                                : null}
                            </div>}
                            <i><a href="javascript:void(0)" onClick={() => changePassType()}
                              aria-label={passType == "password" ? "show Password" : "hide password"}
                              className={passType == "password" ? "fa fa-eye" + " pointer" : "fa fa-eye-slash" + " pointer"}
                              style={{ top: 13, color: "#77aca2" }}
                            ></a></i>
                            {TickCross(formik, "password", "error-circle")}
                          </div>
                        </div>
                      </div> :
                        InputBag() // Render InputBag component for new users
                      }
                      <div className="row g-0">
                        <div className="col-sm-12 col-md-12 col-lg-12"
                          initial={{ y: 50 }}
                          transition={{ type: "spring", duration: 0.8 }}
                          animate={{ y: 0 }}
                          whileTap={{
                            scale: 0.95,
                            transition: { duration: 0.1 },
                          }}                        >
                          <button
                            className="btn_success"
                            type="submit"
                            form="userLoginForm"
                          >
                            Sign In
                          </button>
                        </div>
                      </div>
                      {!isNewUser && <div className="row g-0">
                        <div className="col-sm-12 col-md-12 col-lg-12"
                          initial={{ y: 50 }}
                          transition={{ type: "spring", duration: 0.8 }}
                          animate={{ y: 0 }}
                          whileTap={{
                            scale: 0.95,
                            transition: { duration: 0.1 },
                          }}
                        >
                          <button
                            className="btn_default"
                            onClick={() => signUp()}
                            type="button"
                          >
                            Sign Up
                          </button>
                        </div>
                      </div>}
                      <div className="row g-0">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                          <p
                            className="password pointer m-0"
                          >
                            <a className="txtDecoration" href="javascript:void(0)"
                              onClick={() => setIsOpenForgotPwdModal(true)}>Forgot Password?</a>
                          </p>
                        </div>
                      </div>
                      {/* Optional HCA branding */}
                      {domainName == "HCA" && <div className="row g-0" >
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12" >
                          <p
                            className="password pointer m-0"
                            style={{ paddingTop: 10 }}
                          >
                            <span
                              className="txtDecoration"
                              style={{ fontSize: 13 }}
                            >
                              powered by
                              <img
                                src={logo}
                                className="img-fluid"
                                style={{ maxWidth: 68, marginLeft: 2 }}
                              /></span>
                          </p>
                        </div>
                      </div>}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </motion.section>

          {/* Forgot Password Modal */}
          {isOpenForgotPwdModal && <ForgetPassword
            show={isOpenForgotPwdModal}
            close={() => {
              setIsOpenForgotPwdModal(false);
            }}
            setSubmitForgotPasswordData={setSubmitForgotPasswordData}
          />}

          {/* Reset Password Modal */}
          {showResetPasswordModal && <ResetPassword
            show={showResetPasswordModal}
            close={() => {
              dispatch(setShowResetPasswordModal(false));
            }}
            setSubmitResetPasswordData={setSubmitResetPasswordData}
          />}

          {/* Temporary Password Modal */}
          {resetModalIsOpen && <ForgotTempPassword
            show={resetModalIsOpen}
            close={() => dispatch(setResetModalIsOpen(false))}
            setSubmitResetPasswordData={setSubmitResetPasswordData}
          />}
        </>}
    </>
  );
};

export default Login;
