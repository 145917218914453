import HealthcareLogo from "../../assets/images/healthcare_download.png";
import { HCDLSections } from "../insurance/InsuranceInfo";
import { store } from "../../store";
import { useState } from "react";
import { useGetUser } from "../../utilities/useGetUser";
import { useDispatch } from "react-redux";
import { saveProfile } from "../../reducers/submitProfile";
import { successToast, errorToast } from "../toast/toast";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";


const HCDLSection = ({ containerClass, isPhone, setShowHcdl }) => {
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [error, setError] = useState(false);

    const user = useGetUser();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // get state from redux store
    const reduxState = store.getState();

    // get insurance information of user
    const insuranceInfo = reduxState.global.insuranceInfo;

    const submitInsuranceForm = (data) => {
        if (!checked) {
            setError(true);
            return;
        }
        setLoading(true);
        let body = {
            firstName: user?.firstName,
            middleName: user?.middleName,
            lastName: user?.lastName,
            dob: user?.dob,
            gender: user?.gender,
            bin: insuranceInfo?.bin,
            pcn: insuranceInfo?.pcn,
            groupNumber: insuranceInfo?.groupNumber,
            memberId: insuranceInfo?.memberId,
            appUserSeqNo: user?.appUserSeqNo,
        };
        let extendedBody = {
            insInfoSeqNo: insuranceInfo?.insInfoSeqNo,
            patientSeqNo: user?.patientSeqNo,
            address: insuranceInfo?.address,
            hasAlerts: false,
            hcdlEnrolled: user?.hcdlEnrolled,
            formSubmitted: user?.formSubmitted,
            optin: user?.optin,
            hcdlFlag: checked,
            ...body
        }
        const windowRef = window.open();

        dispatch(saveProfile(extendedBody))
            .unwrap()
            .then((response) => {
                setLoading(false);
                if (response?.statusCode === 200 & response?.status === "OK")
                    if (response?.statusCode === 200 & response?.status === "OK") {
                        localStorage.setItem("user", JSON.stringify({
                            ...user,
                            optin: response?.data?.optin,
                            formSubmitted: response?.data?.formSubmitted,
                            hcdlFlag: response?.data?.hcdlFlag,
                            hcdlEnrolled: response?.data?.hcdlEnrolled
                        }));

                        // if hcdl enrollment link received
                        if (response?.data?.hcdlEnrollmentLink) {
                            sessionStorage.setItem('redirected', 'true');
                            windowRef.location = response.data.hcdlEnrollmentLink;
                            setShowHcdl(false);
                        }
                        else {
                            windowRef.close();
                        }

                        // successToast(response?.message, "showAlert");
                        // navigate("/medications");
                    } else if (response?.status !== "OK") {
                        windowRef.close();
                        errorToast(response?.message);
                    }
            })
            .catch((e) => {
                windowRef.close();
                setLoading(false);
            });
    }


    return (
        <div>
            <div className={containerClass} style={{ marginTop: 12, marginBottom: isPhone ? 0 : 12 }}>
                {loading &&
                    <div style={{ zIndex: 1001, position: "absolute", right: isPhone? "50%" : "31%" }}>
                        <Spinner animation="border" variant="primary" />
                    </div>
                }
                <div >
                    <img src={HealthcareLogo} style={{ width: isPhone ? "2em" : "3em", height: isPhone ? "2em" : "3em", marginBottom: 16, marginRight: "0.4em" }} />
                    <div style={{ fontSize: 14, fontWeight: 600, display: "inline-block", maxWidth: "11em", lineHeight: 1 }}>Get The Most Accurate Drugs Cost</div>
                </div>
                {!isPhone && <p style={{ fontSize: 12, marginTop: -6 }}>
                    Complete your Healthcare Download registration and RxLink can use realtime information on out-of-pocket
                    costs from your insurance to automatically ensure you pay the lowest cost for every drug--either with your insurance or our discounts!
                </p>}
                {isPhone && <p style={{ fontSize: 12, marginTop: -8 }}>
                    RxLink can use realtime information on out-of-pocket
                    costs from your insurance to automatically ensure you pay the lowest cost for every drug.
                </p>}
                <p style={{ fontSize: 12, marginTop: 2 }}>
                    Clicking below will redirect you to the Healthcare Download website to complete your registration.
                    When that's complete click the link on their page to return to RxLink.
                </p>
                <p style={{ fontSize: 12, marginTop: 2 }}>
                    Please have your username and password for your insurer's website ready when you click below.
                </p>
                {!isPhone && <div className={`col-12 col-sm-12 col-md-12 col-lg-12 mb-1 mt-2`}>
                    <h1 style={{ fontSize: 14, color: "#4b4c4d", fontWeight: 600, lineHeight: 1 }}>
                        To reflect the most current insurance coverage, you can enroll with our partner, Healthcare Download.
                    </h1>
                </div>}
                <div className="col-12 col-sm-12 col-md-12 col-lg-12" style={{ fontSize: 11 }}>
                    <input type="checkbox" aria-labelledby={""}
                        name='terms'
                        checked={checked}
                        className="p-1 mr-2" onChange={(e) => {
                            setChecked(e.target.checked);
                            if (e.target.checked) setError(false);
                        }
                        }
                        id="hcdltermsCheckBox"
                        aria-label="terms"
                    />
                    <p style={{ display: "inline", fontSize: 11, color: error ? "red" : "" }}> By checking the box and submitting your request, you agree to let us share your name and phone number with Healthcare Download to simply your registration on their site. </p>
                </div>
                <button
                    className="btn_success review-coupon-button mt-2"
                    onClick={() => submitInsuranceForm()}
                >
                    Register
                </button>
            </div>
            <div style={{ height: 8 }}></div>
        </div>
    )
}
export default HCDLSection;