import { useEffect, useState, useRef } from "react";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { sndSMSOrEmail } from "../../reducers/sendSMSOrEmail";
import { setSMSOrEmailResponse } from "../../reducers/sendSMSOrEmail";
import {
  setFromCoupon,
  setShowCoupon,
  setPencilClick,
} from "../../reducers/medication";
import {
  membersAssign,
  setHippoCardInfo,
  createRxSenseCard,
  setRxSenseCardInfo,
  createConnectiveRxCards,
  setConnectiveCards,
} from "../../reducers/assignMembers";
import { httpClientJava } from "../../environment";
import { getCrxBestPrice } from "../../utilities/GetPrice";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import edit from "../../assets/images/RxLink assets/new assets/edit pen.svg";
import avatar from "../../assets/images/RxLink assets/avatar icon.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SendSMSOrEmailModalWeb } from "./Modals/SendSMSorEmailModal";
import FAQ from "../FAQ";
import { errorToast } from "../toast/toast";

import { useStyleMediaQuery } from "./hooks";
import { searchPharmacyLogo } from "../PharmacyLogo";
import { getCard, getCardDetail, getBestPrice } from "../../utilities/cardUtils";
import { usernameValidation } from "../Fields/Username";
import { getDomainName } from "../../utilities/useGetDomain";
import { store } from "../../store";
import ariaAnnounce from "../../utilities/useAnnounceText";
import LoadingComponent from "../LoadingComponent";
import { saveCareCardInfo, setSaveCareCardInfo } from "../../reducers/careCardInfo";
import { StringToHTML } from "../../utilities/StringToHtml";

const CouponCard = (props) => {
  var userDetail = JSON.parse(localStorage.getItem("user"));
  const {
    windowWidth,
    cheaperOptionAvailable,
    originalFilter,
    pharmacyInfo,
    ownPosition,
  } = props;

  const pharmacyState = JSON.parse(sessionStorage.getItem("selectMapValues"));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const state = useSelector((state) => state.medication.couponData);

  const { healthSystem, pid } = getDomainName();

  const [pharmacistPara, setPharmacistPara] = useState(false);
  const [showMoreInstructions, setShowMoreInstructions] = useState(false);

  const [url, setUrl] = useState("");
  const [isVisibleSMSModal, setIsVisibleSMSModal] = useState(false);
  const [memberAssign, setMemberAssign] = useState("");
  const [isComingSoon, setIsComingSoon] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [isSMS, setIsSMS] = useState(false);
  const [isEmailValue, setEmailValue] = useState();
  const [emailError, setEmailError] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidEmailModal, setIsValidEmailModal] = useState("12");

  const [email, setEmail] = useState("");
  const [isEmailOrSMSSuccess, setIsEmailOrSMSSuccess] = useState(false);

  const [loadingRxCard, setLoadingRxCard] = useState(false);
  const [loadingHippoCard, setLoadingHippoCard] = useState(false);
  const [loadingConnectiveCard, setLoadingConnectiveCard] = useState(false);

  const [termsSMSorEmail, setTermsSMSorEmail] = useState(false);
  const [termsError, setTermsError] = useState(false);

  const reduxState = store.getState();
  const rxSenseCardInfo = reduxState.membersAssignAPI.rxSenseCardInfo;
  const memberInfo = reduxState.membersAssignAPI.hippoCardInfo;
  const connectiveCards = reduxState.membersAssignAPI.connectiveCards;
  const careCards = reduxState?.careCardDetails?.careCardSave;
  const emailRef = useRef(null);

  useEffect(() => {
    if (true) {
      const membersAssignData = JSON.parse(
        localStorage.getItem("membersAssign")
      );
      if (membersAssignData) {
        setMemberAssign(membersAssignData);
        dispatch(setHippoCardInfo(membersAssignData));
        return;
      }
      let body = {
        appUserSeqNo: userDetail?.appUserSeqNo,
      };
      setLoadingHippoCard(true);
      dispatch(membersAssign(body))
        .unwrap()
        .then((response) => {
          setLoadingHippoCard(false);
          setMemberAssign(JSON.parse(response?.data));
          dispatch(setHippoCardInfo(JSON.parse(response?.data)));
          localStorage.setItem("membersAssign", response?.data);
        })
        .catch((e) => {
          setLoadingHippoCard(false);
        });
    }
  }, []);

  useEffect(() => {
    if (true) {
      const rxSenseCardData = JSON.parse(localStorage.getItem("rxSenseCard"));
      if (rxSenseCardData) {
        dispatch(setRxSenseCardInfo(rxSenseCardData));
        return;
      }
      let body = {
        appUserSeqNo: userDetail?.appUserSeqNo,
      };
      setLoadingRxCard(true);
      dispatch(createRxSenseCard(body))
        .unwrap()
        .then((response) => {
          if (response?.data) {
            dispatch(setRxSenseCardInfo(response?.data));
            localStorage.setItem("rxSenseCard", JSON.stringify(response?.data));
          } else {
            dispatch(
              setRxSenseCardInfo({
                bin: "",
                pcn: "",
                memberId: "",
                groupId: "",
              })
            );
          }
          setLoadingRxCard(false);
        })
        .catch((e) => {
          setLoadingRxCard(false);
        });
    }
  }, []);

  const getConnectiveCardDrugs = (drugs) => {
    let filteredConnectiveDrugs = drugs.filter(
      (dr) => dr?.selectedDrug?.connectiveRxOffer?.hasCard == 1
    );
    return filteredConnectiveDrugs || [];
  };

  useEffect(() => {
    let connectiveDrugs = getConnectiveCardDrugs(state.medDrugData);
    if (connectiveDrugs.length > 0) {
      let body = [];
      connectiveDrugs.map((it) => {
        body.push({
          appUserSeqNo: userDetail?.appUserSeqNo,
          drugNdc: it?.selectedDrug?.connectiveRxOffer?.drugNdc,
          crxNetPriceSubSeqNo:
            it?.selectedDrug?.connectiveRxOffer?.crxNetPriceSubSeqNo,
          dob: userDetail?.dob,
          gender: userDetail?.gender,
          patientSeqNo: userDetail?.patientSeqNo,
          pharmacySeqNo: pharmacyState?.pharmacySeqNo || "1213",
          pharmacyState:
            pharmacyState?.state || ownPosition?.responseZIP?.region_code || "",
          zipcode: pharmacyState?.zipCode || ownPosition?.postalCode || "",
          campaignEndDate: it?.selectedDrug?.connectiveRxOffer?.campaignEndDate,
          npi: originalFilter?.npi,
          partner: it?.selectedDrug?.connectiveRxOffer?.partner
        });
      });
      setLoadingConnectiveCard(true);
      dispatch(
        createConnectiveRxCards({
          appUserSeqNo: userDetail?.appUserSeqNo,
          connectiveRxRequest: body,
        })
      )
        .unwrap()
        .then((response) => {
          if (response?.statusCode == 200) {
            let cards =
              response?.data?.length > 0 &&
              response?.data?.map((connective) => {
                if (connective?.statusCode == 200 && connective?.partner === "CRx") {
                  return {
                    bin: connective?.programs?.[0]?.bin,
                    pcn: connective?.programs?.[0]?.pcn,
                    groupId: connective?.programs?.[0]?.group,
                    memberId: connective?.programs?.[0]?.memberId,
                    ndc: connective.drugNdc,
                  };
                }
                else if (connective?.statusCode == 200 && connective?.partner === "Doceree") {
                  return {
                    bin: connective?.docereeResponse?.bin,
                    pcn: connective?.docereeResponse?.pcn,
                    groupId: connective?.docereeResponse?.groupId,
                    memberId: connective?.docereeResponse?.memberID,
                    ndc: connective.drugNdc,
                  };
                }
              });
            dispatch(setConnectiveCards(cards));
          } else {
            dispatch(setConnectiveCards([]));
          }
          setLoadingConnectiveCard(false);
        })
        .catch((e) => {
          setLoadingConnectiveCard(false);
        });
    }
  }, []);

  const navigateToCouponSent = () => {
    setIsEmailOrSMSSuccess(true);
  };

  const termsHandler = (e) => {
    setTermsSMSorEmail(e.target.checked);
    if (e.target.checked) {
      setTermsError(false);
    }
  };
  const getServiceProvider = (med) => {
    if (med.hasInsurance) return "1";
    else if (med.rxSense) return "3";
    else return "2";
  };

  const checkSelected = (med, pharmacy) => {
    if (med?.pharmacyName === pharmacy?.pharmacyName) return 1;
    else return 0;
  };

  const checkBestPrice = (med) => {
    let chk = 1;
    let { id } = med.selectedDrug;
    state.pharmacyDrugsInfo.forEach((pharmacy, index) => {
      if (state?.drug?.pharmacyName !== pharmacy?.pharmacyName) {
        pharmacy.drugs.forEach((medicine, i) => {
          if (
            id == medicine.selectedDrug.id &&
            medicine.bestPrice < med.bestPrice
          ) {
            chk = 0;
          }
        });
      }
    });
    return chk;
  };

  useEffect(() => {
    if (state.pharmacyDrugsInfo && state.pharmacyDrugsInfo.length > 0) {
      const drugArr = [];
      const sessionPostal = window.sessionStorage.getItem("postalCode");

      state?.pharmacyDrugsInfo.forEach((pharmacy, index) => {
        pharmacy.drugs.forEach((med, i) => {
          let tempBody = {
            insuranceSeqNo: med.hasInsurance
              ? props?.insuranceDetail?.[0]?.insInfoSeqNo
              : null,
            varientId: med.selectedDrug.id,
            drugName: med.selectedDrug.name,
            form: med.selectedDrug.form,
            drugType: med.selectedDrug.type,
            dosage: med.selectedDrug.dosage,
            default_quantity: med.selectedDrug.default_quantity,
            ndc: med.selectedDrug.ndc,
            chain: pharmacy.chain,
            cost: med.bestPrice,
            estRetailPrice: med.estRetailPrice,
            pharmacyName: pharmacy.pharmacyName,
            zipcode: props?.ownPosition?.postalCode
              ? props.ownPosition.postalCode
              : "70005",
            serviceProvider: getServiceProvider(med),
            suggested: checkSelected(med, state.drug),
            bestPrice: checkBestPrice(med),
          };

          drugArr.push(tempBody);
        });
      });
      var userDetail = JSON.parse(localStorage.getItem("user") || []);

      httpClientJava
        .post("medication/savePatientPrices", {
          appUserSeqNo: userDetail.appUserSeqNo,
          patientPrices: drugArr,
        })
        .then((res) => {
          console.log({ res });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [state]);

  useEffect(() => {
    
    if (state?.medDrugData?.length > 0) {
      const getUniqueCareCards = (careCardArr, keys) => {
        return careCardArr.filter((item, index, self) =>
          index === self.findIndex((t) => (
            keys.every(key => t[key] === item[key])
          ))
        );
      };
      const uniqueCareCardArr = state?.medDrugData?.filter((x) => { if (x?.isCareCard) { return x; } });
      if (uniqueCareCardArr?.length > 0) {
        uniqueCareCardArr?.forEach((element, ind) => {
          if (element?.isCareCard) {
            let saveCareCardBody = {
              "appUserSeqNo": JSON.parse(localStorage.getItem("user"))?.appUserSeqNo,
              "ndc": element.selectedDrug.ndc,
              "price": element?.bestPrice,
              "estRetailPrice": element?.estRetailPrice,
              "quantity": element.selectedDrug.default_quantity,
              "pharmacyName": originalFilter.pharmacyName,
              "pharmacyNpi": originalFilter?.npi,
              "zipcode": originalFilter?.zipCode ? originalFilter?.zipCode : ownPosition?.postalCode,
              "bin": element?.careCardInfo?.bin,
              "pcn": element?.careCardInfo?.pcn,
              "groupId": element?.careCardInfo?.groupId,
              "memberId": element?.careCardInfo?.memberId
            };
            if (saveCareCardBody?.appUserSeqNo) {
              dispatch(saveCareCardInfo(saveCareCardBody))
                .unwrap().then((response) => {
                  if (response?.data) {
                    dispatch(setSaveCareCardInfo(response?.data));
                  }
                })
            }
          }
        })
      }


    }
  }, [state?.medDrugData])

  const validateEmail = (e) => {
    var email = e.target.value;
    setEmailValue(email);
    setEmail(e.target.value);
    if (validator.isEmail(email)) {
      setEmailError("");
      setIsValidEmail(true);
      setIsValidEmailModal(true);
    } else {
      setIsValidEmail(false);
      setEmailError("Enter valid Email");
      setIsValidEmailModal(false);
    }
  };
  function processInput(input) {
    if (typeof input === 'string' && input.startsWith('[') && input.endsWith(']')) {
      // Remove the brackets
      let trimmedInput = input.slice(1, -1);

      // Split the string by comma and trim each element
      let arrayOfStrings = trimmedInput.split(',').map(item => item.trim());

      return arrayOfStrings;
    } else {
      // Assume the input is already in the correct format
      return input;
    }
  }

  const sendSMS = (data) => {
    if (termsSMSorEmail) {
      setTermsError(false);
      if (data) {
        const drugArr = [];
        for (let index = 0; index < state?.medDrugData?.length; index++) {
          if (state?.medDrugData[index]?.chain || state?.medDrugData[index]?.bestPrice > 0 || state?.medDrugData[index]?.hasInsurance) {
            let med = state.medDrugData[index];
            let names = processInput(state?.medDrugData[index]?.selectedDrug?.names);

            const getName = () => {
              if (names.length > 1) {
                return (
                  names[1] +
                  ` (${state?.medDrugData[index]?.selectedDrug?.name})`
                );
              }
              else if (names.length == 1) {
                return state?.medDrugData[index]?.selectedDrug?.name
              }
            }
            let tempBody = {
              name: getName(),
              dosage: state?.medDrugData[index]?.selectedDrug?.dosage,
              form: state?.medDrugData[index]?.selectedDrug?.form,
              price:
                med?.selectedDrug?.connectiveRxOffer?.hasCard == 1
                  ? med?.selectedDrug?.connectiveRxOffer?.price
                  : med?.bestPrice,
              default_quantity:
                state?.medDrugData[index]?.selectedDrug?.default_quantity,
              cardInfo: getCard(
                med,
                rxSenseCardInfo,
                memberInfo,
                connectiveCards,
                careCards
              ),
            };
            drugArr.push(tempBody);
          }
        }

        setLoading(true);

        let body = {
          sendSMS: true,
          mobileNo: data?.username.match(numberPattern).join(""),
          appUserSeqNo: JSON.parse(localStorage.getItem("user")).appUserSeqNo,
          memberInfo: state?.memberAssign ? state?.memberAssign : null,
          longitude: pharmacyState?.lng || props?.ownPosition?.lng,
          latitude: pharmacyState?.lat || props?.ownPosition?.lat,
          pharmacyName: state.drug.pharmacyName,
          address: originalFilter ? originalFilter?.address : "",
          drugs: drugArr,
          state: pharmacyState?.state,
          walletHash: JSON.parse(localStorage.getItem("user")).walletHash,
          pid: (userDetail?.pid != null && userDetail?.pid != undefined && userDetail?.pid != "") ? userDetail?.pid : pid,
        };
        dispatch(sndSMSOrEmail(body))
          .unwrap()
          .then((response) => {
            setLoading(false);

            if (response?.status === "OK") {
              setIsSMS(false);
              navigateToCouponSent();
              setTermsSMSorEmail(false);
              dispatch(setSMSOrEmailResponse(response?.data?.memberInfo));
              setIsVisibleSMSModal(false);
              localStorage.setItem(
                "memberInfo",
                JSON.stringify(response?.data?.memberInfo)
              );
              setUrl(response?.data?.walletUrl);
            } 
            else if(response?.status === "BANDWIDTH_LIMIT_EXCEEDED"){
              errorToast(<StringToHTML htmlString={"The coupon was already sent to this number. Please wait 10 minutes and if the coupon is still not received, try to use the email or mobile wallet option or email us at: <a href='mailto:support@rxlink.com'>support@rxlink.com</a>"} />);
              setTermsSMSorEmail(false);
            }
            else {
              errorToast(response?.message);
              setTermsSMSorEmail(false);
            }
          })
          .catch((e) => {
            setLoading(false);
          });
      }
    } else {
      setTermsError(true);
    }
  };



  const sendEmail = (flag) => {
    const { lat, lng, postalCode } = props.ownPosition;
    if (!isEmailValue) {
      setEmailError("Email is required");
      emailRef.current && emailRef.current.focus();
      return;
    }

    if (!isValidEmail) {
      setEmailError("Enter valid email");
      emailRef.current && emailRef.current.focus();
      return;
    }

    if (termsSMSorEmail) {
      setTermsError(false);
    } else {
      setTermsError(true);
      return;
    }

    if (flag) {
      setLoading(true);
      const drugArr = [];
      for (let index = 0; index < state?.medDrugData.length; index++) {
        if (state?.medDrugData[index]?.chain || state?.medDrugData[index]?.bestPrice > 0 || state?.medDrugData[index]?.hasInsurance) {
          let med = state.medDrugData[index];
          let names = processInput(state?.medDrugData[index]?.selectedDrug?.names);

          const getName = () => {
            if (names.length > 1) {
              return (
                names[1] +
                ` (${state?.medDrugData[index]?.selectedDrug?.name})`
              );
            }
            else if (names.length == 1) {
              return state?.medDrugData[index]?.selectedDrug?.name
            }
          }
          let tempBody = {
            name: getName(),
            dosage: state?.medDrugData[index]?.selectedDrug?.dosage,
            form: state?.medDrugData[index]?.selectedDrug?.form,
            price:
              med?.selectedDrug?.connectiveRxOffer?.hasCard == 1
                ? med?.selectedDrug?.connectiveRxOffer?.price
                : med?.bestPrice,
            default_quantity: parseInt(
              state?.medDrugData[index]?.selectedDrug?.default_quantity
            ),
            cardInfo: getCard(med, rxSenseCardInfo, memberInfo, connectiveCards, careCards),
          };
          drugArr.push(tempBody);
        }
      }
      let body = {
        sendSMS: false,
        appUserSeqNo: JSON.parse(localStorage.getItem("user")).appUserSeqNo,
        email: email.match(emailPattern).join(""),
        pharmacyName: state?.drug?.pharmacyName,
        price: "$" + getCrxBestPrice(state?.drug),
        drugs: drugArr,
        longitude: pharmacyState?.lng || props?.ownPosition?.lng,
        latitude: pharmacyState?.lat || props?.ownPosition?.lat,
        state: pharmacyState?.state,
        walletHash: JSON.parse(localStorage.getItem("user")).walletHash,
        address: originalFilter ? originalFilter?.address : "",
        pid: (userDetail?.pid != null && userDetail?.pid != undefined && userDetail?.pid != "") ? userDetail?.pid : pid,
      };
      dispatch(sndSMSOrEmail(body))
        .unwrap()
        .then((response) => {
          setLoading(false);
          if (response?.status === "OK") {
            setIsEmail(false);
            setEmailValue("");
            setTermsSMSorEmail(false);
            dispatch(setSMSOrEmailResponse(response?.data?.memberInfo));
            localStorage.setItem(
              "memberInfo",
              JSON.stringify(response?.data?.memberInfo)
            );
            navigateToCouponSent();
          } else {
            setEmailValue("");
          }
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  };

  const IMHere = (e) => {
    setPharmacistPara(e);
    if (state?.isPharmacyInsShow) {
      navigate("/medications");
    } else {
      return;
    }
    if (JSON.parse(localStorage.getItem("memberInfo"))) {
      setMemberAssign(JSON.parse(localStorage.getItem("memberInfo")));
    } else {
      let body = {
        appUserSeqNo: userDetail?.appUserSeqNo,
      };
      setLoading(true);
      dispatch(membersAssign(body))
        .unwrap()
        .then((response) => {
          setLoading(false);
          if (response?.data) {
            setMemberAssign(JSON.parse(response?.data));
          }
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  };

  function logout() {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
  }

  const medications = () => {
    if (showMoreInstructions) {
      setShowMoreInstructions(false);
      setPharmacistPara(false);
    } else {
      dispatch(setFromCoupon(true));
      dispatch(setShowCoupon(false));
      ariaAnnounce("my medication savings");
    }
  };

  const goMedications = () => {
    let obj = {
      pencilClick: true,
    };
    dispatch(setShowCoupon(false));
    dispatch(setPencilClick(true));
    navigate("/medications", { state: obj });
  };

  useEffect(() => {
    setPharmacistPara(state?.isPharmacyInsShow);
  }, [props.isSaveValueForCoupon]);

  var numberPattern = /\d+/g;
  var emailPattern = "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$";
  const validationSchema = Yup.object().shape({
    username: usernameValidation,
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      terms: true,
    },
    validationSchema,
    onSubmit: (data) => {
      sendSMS(data);
    },
  });


  const openSMSpopup = (e) => {
    setIsSMS(e);
    formik.values.username = "";
    ariaAnnounce("Send a coupon via SMS");
  };
  const openEmailPopup = (e) => {
    setIsEmail(e);
    ariaAnnounce("Send a coupon via e-Mail")
    setEmailError("");
  };

  const closeSMSOrEmailPopup = (e) => {
    setTermsError(false);
    setTermsSMSorEmail(false);
    setIsEmailOrSMSSuccess(e);
    formik.setTouched({}, false);
    setIsSMS(e);
    setIsEmail(e);
  };

  console.log({ showMoreInstructions });

  const openMoreInformation = () => {
    setShowMoreInstructions(true);
    ariaAnnounce("FAQ’s About Using RxLink Coupons")
  };

 
  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          <section className=" mt-3">
            {!pharmacistPara && !showMoreInstructions && (
              <div className="container">
                <div className="webView">
                  <div className="row ">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="medicine_icon pharma_icon back_icon">

                        <a
                          className="txtDecoration"
                          href="javascript:void(0)"
                          aria-label="Go Back"
                          onClick={() => medications()}
                        >
                          <i className="fas fa-angle-left"></i>
                        </a>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="medicine_icon medicine_icon_m">
                        <div
                          id="dropdownMenuButton1"
                          className="img-fluid pointer"
                          type="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRight"
                          aria-controls="offcanvasRight"
                        >
                          <a href="javascript:void(0)">
                            <img
                              whileTap={{
                                scale: 0.95,
                                transition: { duration: 0.1 },
                              }}
                              src={avatar}
                              alt="Account"
                            ></img>
                          </a>
                        </div>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a
                            href="javascript:void(0)"
                            className="dropdown-item item-1"
                            onClick={() => logout()}
                          >
                            Logout
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div role="alert">
                  {state && (
                    <div className="webView showDivonWeb" id="couponPageHeading">
                      <div className="row ">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <div className="medication-heading">
                            <h1>My medication savings</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {state && (
                    <div className="row coupon_main">
                      <div className="col-sm-12 col-md-12 col-lg-3 mobileView">
                        <div className="medications_inner medications_inner_coupon dlr-Sup  medications_inner_imhere ">
                          <div className="row">
                            <div className="col-6 col-sm-6 col-md-6 col-lg-12 ">
                              <label className="" tabIndex={0} aria-label="TOTAL OUT OF POCKET">TOTAL OUT OF POCKET 0</label>
                              <div className="med-Flex">
                                <h1 className="webView div-hidden" tabIndex={0} aria-label={"$" + getCrxBestPrice(state?.drug)}>
                                  <sup>
                                    <b>$&nbsp;</b>
                                  </sup>
                                  {getCrxBestPrice(state?.drug, true)}{" "}
                                </h1>
                                <h2 className="tabView" tabIndex={0} aria-label={"$" + getCrxBestPrice(state?.drug)}>
                                  <sup>
                                    <b>$&nbsp;</b>
                                  </sup>
                                  {getCrxBestPrice(state?.drug, true)}{" "}
                                </h2>
                                <h3
                                  className="mobileView"
                                  style={{ display: "flex" }}
                                  tabIndex={0}
                                  aria-label={"$" + getCrxBestPrice(state?.drug)}
                                >
                                  <sup style={{ paddingTop: 10, fontSize: 11 }}>
                                    <b>$&nbsp;</b>
                                  </sup>
                                  {getCrxBestPrice(state?.drug, true)}{" "}
                                </h3>
                                {state?.savingPercentage > 0 && (
                                  <p className="font13 flex-end" tabIndex={0} aria-label={Math.round(state?.savingPercentage) + "% off"}>
                                    {Math.round(state?.savingPercentage)}
                                    %&nbsp;off{" "}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-6 col-sm-6 col-md-6 col-lg-12 autoMargin">
                              <button
                                className="btn_default"
                                onClick={() => medications()}
                              >
                                Hide coupons
                              </button>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12">
                              <label className="hrDiv">PHARMACYs</label>
                              <div className="review-coupon ">
                                <div className="row ">
                                  <div className="col-3 col-sm-3 col-md-3 col-lg-3 autoMargin">
                                    <div className="pharmacyImg_div">
                                      <img
                                        src={state?.drug?.logo?.small}
                                        className="img-fluid "
                                        alt={state?.drug?.pharmacyName + " Logo"}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-7 col-sm-7 col-md-7 col-lg-7 autoMargin">
                                    <div className="">
                                      <p className="shopName">
                                        {" "}
                                        {state?.drug?.pharmacyName}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-2 col-sm-2 col-md-2 col-lg-2 autoMargin text-center">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={() => goMedications()}
                                    >
                                      <img
                                        className="cursor-pointer"
                                        src={edit}
                                        alt="Change pharmacy"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="row webView">
                                <div className="col-6 col-sm-6 col-md-12 col-lg-12 ">
                                  <button
                                    className="btn_default"
                                    onClick={() => medications()}
                                  >
                                    Hide coupons
                                  </button>
                                </div>
                              </div>
                              <h4 className="row mb-2 mobileView">
                                Save and keep your coupons handy
                              </h4>
                              <motion.h4
                                className="row mb-2 webView"
                                initial={{ y: -30 }}
                                animate={{ y: 0 }}
                                transition={{ duration: 0.5 }}
                              >
                                Save your discounts to keep them handy
                              </motion.h4>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                              <div className="row">
                                <motion.div
                                  className="col-6 col-sm-6 col-md-12 col-lg-12"
                                  initial={{ y: -30 }}
                                  animate={{ y: 0 }}
                                  transition={{ duration: 0.5 }}
                                >
                                  <button
                                    className="btn_success"
                                    onClick={() => openSMSpopup(true)}
                                  >
                                    SMS
                                  </button>
                                </motion.div>
                                <div className="col-6 col-sm-6 col-md-12 col-lg-12">
                                  <button
                                    className="btn_success"
                                    onClick={() => openEmailPopup(true)}
                                  >
                                    Email
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 "></div>
                          </div>
                        </div>
                      </div>
                      <motion.div
                        className="col-sm-12 col-md-12 col-lg-9 showDivonWeb"
                        initial={{ y: -50 }}
                        animate={{ y: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <motion.div className="medications_inner medications_inner_coupon  dlr-Sup">
                          <div className="row">
                            {!showMoreInstructions ? (
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <p className="coupon_link" style={{ fontSize: 16, fontWeight: "bold" }}>
                                  Show the coupons and{" "}
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() => IMHere(true)}
                                    className="pharmacyINC text-underline"
                                  >
                                    pharmacy instructions
                                  </a>{" "}
                                  to your pharmacy staff.
                                  <a
                                    style={{ marginLeft: 4 }}
                                    href="javascript:void(0)"
                                    onClick={() => openMoreInformation()}
                                    className="pharmacyINC text-underline"
                                  >
                                    More Information on how to use your coupons.
                                  </a>
                                </p>
                                <div className="container">
                                  <div className="med_padding">
                                    <div className="">
                                      {loadingHippoCard ||
                                        loadingRxCard ||
                                        loadingConnectiveCard ||
                                        !rxSenseCardInfo ||
                                        !memberInfo ? (
                                        <LoadingComponent />
                                      ) : (
                                        state?.medDrugData?.map((med, index) => {
                                          return (
                                            <>
                                              {(med?.chain != undefined || med?.bestPrice > 0 || med?.hasInsurance) && (
                                                <>
                                                  <span className="normal mt-3 mb-3">
                                                    {med?.hasInsurance
                                                      ? "Use with insurance"
                                                      : "With RxLink Offer"}
                                                  </span>

                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                    <motion.div
                                                      className="card_info_new "
                                                      initial={{ y: -100 }}
                                                      animate={{ y: 0 }}
                                                      transition={{
                                                        damping: 12,
                                                        stiffness: 200,
                                                        mass: 0.5,
                                                        type: "spring",
                                                        duration: 0.3,
                                                      }}
                                                    >
                                                      <div className="row">
                                                        <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                                          <div className="card_info_new_left">
                                                            <span className="webView" style={{ fontSize: 20, fontWeight: "bold", textTransform: "capitalize" }}>
                                                              {
                                                                med?.selectedDrug
                                                                  ?.name
                                                              }
                                                            </span>
                                                            <span className="mobileView" style={{ fontSize: 16, fontWeight: "bold", textTransform: "capitalize" }}>
                                                              {
                                                                med?.selectedDrug
                                                                  ?.name
                                                              }
                                                            </span>
                                                            <p className="mt-2">
                                                              {med?.selectedDosage}
                                                              {med?.selectedDosage &&
                                                                ", "}
                                                              {med?.selectedQty}{" "}
                                                              {med?.selectedForm}
                                                            </p>
                                                          </div>
                                                        </div>
                                                        <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                                          <div className="card_info_new_right">
                                                            {med?.selectedDrug
                                                              ?.connectiveRxOffer
                                                              ?.hasCard != 1 &&
                                                              (med?.bestPrice > 0 || med?.hasInsurance) ? (
                                                              <span className="mt-2 card_info__" style={{ fontSize: 20, fontWeight: "bold" }}>
                                                                <b>$&nbsp;</b>
                                                                {med?.bestPrice?.toLocaleString(
                                                                  undefined,
                                                                  {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }
                                                                )}
                                                              </span>
                                                            ) : med?.selectedDrug
                                                              ?.connectiveRxOffer
                                                              ?.hasCard == 1 ? (
                                                              <span className="mt-2 card_info__" style={{ fontSize: 20, fontWeight: "bold" }}>
                                                                <b>$&nbsp;</b>
                                                                {parseFloat(
                                                                  med?.selectedDrug
                                                                    ?.connectiveRxOffer
                                                                    ?.price
                                                                )?.toLocaleString(
                                                                  undefined,
                                                                  {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }
                                                                )}
                                                              </span>
                                                            ) : (
                                                              <span className="mt-2 card_info__" style={{ fontSize: 20, fontWeight: "bold" }}>
                                                                ---
                                                              </span>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="row">
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                          <div className="text-center">
                                                            <span>
                                                              &nbsp;
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </motion.div>
                                                    <div className="car_info_bottom_new">
                                                      <div className="row">
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                          <div className="couponTable">
                                                            <div className="row">
                                                              <div className="col-6 col-sm-3 col-md-3 col-lg-3">
                                                                <div className="webView">
                                                                  <label>BIN</label>
                                                                  <p className="black-text insrance_card_bottom_label_black opacity_1" style={{ fontSize: 16, fontWeight: "bold" }}>
                                                                    {getCardDetail(
                                                                      "BIN",
                                                                      med,
                                                                      rxSenseCardInfo,
                                                                      memberInfo,
                                                                      connectiveCards
                                                                    )}
                                                                  </p>
                                                                </div>
                                                                <div className="mobileView">
                                                                  <div className="row">
                                                                    <div className="col-sm-12">
                                                                      <label>
                                                                        BIN
                                                                      </label>
                                                                    </div>
                                                                  </div>
                                                                  <div className="row">
                                                                    <div className="col-sm-12">
                                                                      <label className="black-text insrance_card_bottom_label_black opacity_1">
                                                                        {getCardDetail(
                                                                          "BIN",
                                                                          med,
                                                                          rxSenseCardInfo,
                                                                          memberInfo,
                                                                          connectiveCards
                                                                        )}
                                                                      </label>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="col-6 col-sm-3 col-md-3 col-lg-3">
                                                                <div className="webView">
                                                                  <label>PCN</label>
                                                                  <p className="black-text opacity_1" style={{ fontSize: 16, fontWeight: "bold" }}>
                                                                    {" "}
                                                                    {getCardDetail(
                                                                      "PCN",
                                                                      med,
                                                                      rxSenseCardInfo,
                                                                      memberInfo,
                                                                      connectiveCards
                                                                    )}
                                                                  </p>
                                                                </div>
                                                                <div className="mobileView">
                                                                  <div className="row">
                                                                    <div className="col-sm-12">
                                                                      <label>
                                                                        PCN
                                                                      </label>
                                                                    </div>
                                                                  </div>
                                                                  <div className="row">
                                                                    <div className="col-sm-12">
                                                                      <label className="black-text insrance_card_bottom_label_black opacity_1">
                                                                        {getCardDetail(
                                                                          "PCN",
                                                                          med,
                                                                          rxSenseCardInfo,
                                                                          memberInfo,
                                                                          connectiveCards
                                                                        )}
                                                                      </label>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="col-6 col-sm-3 col-md-3 col-lg-3">
                                                                <div className="webView">
                                                                  <label>GROUP</label>
                                                                  <p className="black-text opacity_1" style={{ fontSize: 16, fontWeight: "bold" }}>
                                                                    {" "}
                                                                    {getCardDetail(
                                                                      "GROUP_ID",
                                                                      med,
                                                                      rxSenseCardInfo,
                                                                      memberInfo,
                                                                      connectiveCards
                                                                    )}
                                                                  </p>
                                                                </div>
                                                                <div className="mobileView">
                                                                  <div className="row">
                                                                    <div className="col-sm-12">
                                                                      <label>
                                                                        GROUP
                                                                      </label>
                                                                    </div>
                                                                  </div>
                                                                  <div className="row">
                                                                    <div className="col-sm-12">
                                                                      <label className="black-text insrance_card_bottom_label_black opacity_1">
                                                                        {getCardDetail(
                                                                          "GROUP_ID",
                                                                          med,
                                                                          rxSenseCardInfo,
                                                                          memberInfo,
                                                                          connectiveCards
                                                                        )}
                                                                      </label>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="col-6 col-sm-3 col-md-3 col-lg-3 ">
                                                                <div className="webView">
                                                                  <label>ID</label>
                                                                  <p className="black-text opacity_1" style={{ fontSize: 16, fontWeight: "bold" }}>
                                                                    {" "}
                                                                    {getCardDetail(
                                                                      "MEMBER_ID",
                                                                      med,
                                                                      rxSenseCardInfo,
                                                                      memberInfo,
                                                                      connectiveCards
                                                                    )}
                                                                  </p>
                                                                </div>
                                                                <div className="mobileView">
                                                                  <div className="row">
                                                                    <div className="col-sm-12">
                                                                      <label>
                                                                        ID
                                                                      </label>
                                                                    </div>
                                                                  </div>
                                                                  <div className="row">
                                                                    <div className="col-sm-12">
                                                                      <label className="black-text insrance_card_bottom_label_black opacity_1">
                                                                        {getCardDetail(
                                                                          "MEMBER_ID",
                                                                          med,
                                                                          rxSenseCardInfo,
                                                                          memberInfo,
                                                                          connectiveCards
                                                                        )}
                                                                      </label>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </>

                                          );
                                        })
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <FAQ />
                              </div>
                            )}
                          </div>
                        </motion.div>
                      </motion.div>
                      <div className="col-sm-12 col-md-12 col-lg-3 webView showDivonWeb">
                        <div className="medications_inner medications_inner_coupon dlr-Sup ">
                          {!showMoreInstructions && (
                            <div className="row">
                              <div className="col-6 col-sm-6 col-md-12 col-lg-12">
                                <h2 className="totalPocket" tabIndex={0} aria-label="TOTAL OUT OF POCKET">TOTAL OUT OF POCKET</h2>
                                <div className="med-Flex">
                                  <span style={{ fontSize: 27, lineHeight: 1.3, fontWeight: "bold" }} className="webView div-hidden" tabIndex={0} aria-label={"$" + getCrxBestPrice(state?.drug)}>
                                    <sup>
                                      <b>$&nbsp;</b>
                                    </sup>
                                    {
                                      getCrxBestPrice(state?.drug, true)
                                    }{" "}
                                  </span>
                                  <span style={{ fontSize: 27, lineHeight: 1.3, fontWeight: "bold" }} className="tabView" tabIndex={0} aria-label={"$" + getCrxBestPrice(state?.drug)}>
                                    <sup>
                                      <b>$&nbsp;</b>
                                    </sup>
                                    {
                                      getCrxBestPrice(state?.drug, true)
                                    }{" "}
                                  </span>
                                  <span
                                    className="mobileView"
                                    style={{ display: "flex", fontSize: 20, fontWeight: "bold", lineHeight: 1.2 }}
                                    tabIndex={0} aria-label={"$" + getCrxBestPrice(state?.drug)}
                                  >
                                    <sup style={{ paddingTop: 10, fontSize: 11 }}>
                                      <b>$&nbsp;</b>
                                    </sup>
                                    {
                                      getCrxBestPrice(state?.drug, true)
                                    }{" "}
                                  </span>
                                  {state?.savingPercentage > 0 && (
                                    <p className="font13 flex-end" tabIndex={0} aria-label={Math.round(state?.savingPercentage) + "% off"}>
                                      {Math.round(state?.savingPercentage)}
                                      %&nbsp;off{" "}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-6 col-sm-6 col-md-12 col-lg-12 mobileView">
                                <button
                                  className="btn_default"
                                  onClick={() => medications()}
                                >
                                  Hide coupons
                                </button>
                              </div>
                              <div className="col-sm-12 col-md-12 col-lg-12 copun_pharmacy">
                                <h3 className="hrDiv totalPocket ">PHARMACY</h3>
                                <div className="review-coupon ">
                                  <div className="row ">
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 autoMargin">
                                      <div className="pharmacyImg_div">
                                        <img
                                          src={searchPharmacyLogo(
                                            state?.drug?.pharmacyName,
                                            state?.drug?.logo?.small,
                                            state?.drug?.chain?.toLowerCase()
                                          )}
                                          className="img-fluid"
                                          alt={
                                            state?.drug?.pharmacyName + " Logo"
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-7 col-sm-7 col-md-7 col-lg- autoMargin">
                                      <div className="">
                                        <p className="shopName">
                                          {" "}
                                          {state?.drug?.pharmacyName}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 autoMargin text-center">
                                      <a
                                        href="javascript:void(0)"
                                        onClick={() => goMedications()}
                                      >
                                        <img
                                          className="cursor-pointer"
                                          src={edit}
                                          alt="Change pharmacy"
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="row webView">
                                  <div className="col-6 col-sm-6 col-md-12 col-lg-12 ">
                                    <button
                                      className="btn_default"
                                      onClick={() => medications()}
                                    >
                                      Hide coupons
                                    </button>
                                  </div>
                                </div>
                                <span className="row mb-2 mobileView" style={{ fontSize: 16 }}>
                                  Save and keep your coupons handy
                                </span>
                                <span className="row mb-2 webView" style={{ fontSize: 16 }}>
                                  <motion.div
                                    className="hrDiv"
                                    initial={{
                                      y: -50,
                                      marginTop: "0px",
                                      paddingTop: "0px",
                                    }}
                                    animate={{
                                      y: 0,
                                      marginTop: "10px",
                                      paddingTop: "20px",
                                    }}
                                    transition={{ duration: 0.5, delay: 0.15 }}
                                  >
                                    Save your discounts to keep them handy
                                  </motion.div>
                                </span>
                              </div>
                            </div>
                          )}
                          <div
                            className={showMoreInstructions ? "row" : "row mt-2"}
                          >
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                              <motion.div
                                className="row"
                                initial={{
                                  marginTop: showMoreInstructions
                                    ? "-0.1rem"
                                    : "-0.1rem",
                                }}
                                animate={{
                                  marginTop: showMoreInstructions
                                    ? "-12px"
                                    : "0.5rem",
                                }}
                                transition={{ duration: 0.5, delay: 0.18 }}
                              >
                                <motion.div
                                  className="col-6 col-sm-6 col-md-12 col-lg-12"
                                  initial={{ y: -50 }}
                                  animate={{ y: 0 }}
                                  transition={{ duration: 0.6 }}
                                >
                                  {/* <MotionButton> */}
                                  <button
                                    className="btn_success"
                                    onClick={() => {
                                      if (showMoreInstructions) {
                                        setShowMoreInstructions(false);
                                        setPharmacistPara(false);
                                      } else {
                                        openSMSpopup(true);
                                      }
                                    }}
                                  >
                                    {showMoreInstructions
                                      ? "Show Coupons"
                                      : "SMS"}
                                  </button>
                                  {/* </MotionButton> */}
                                </motion.div>
                                <motion.div
                                  className="col-6 col-sm-6 col-md-12 col-lg-12"
                                  initial={{ y: -50 }}
                                  animate={{ y: 0 }}
                                  transition={{ duration: 0.5 }}
                                >
                                  {/* <MotionButton> */}
                                  <button
                                    className="btn_success"
                                    onClick={() => {
                                      if (showMoreInstructions) {
                                        setShowMoreInstructions(false);
                                        setPharmacistPara(true);
                                      } else {
                                        openEmailPopup(true);
                                      }
                                    }}
                                  >
                                    {showMoreInstructions
                                      ? "Show Pharmacy Instruction"
                                      : "Email"}
                                  </button>
                                  {/* </MotionButton> */}
                                </motion.div>
                              </motion.div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 ">
                              {/* <hr className=" mobileView"></hr> */}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            {windowWidth > 992 && (
                              <div
                                className="offer-section"
                                style={{ marginTop: 8, marginBottom: 10 }}
                              >
                                {state?.cheaperOptionAvailable ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i
                                      class="fa-solid fa-circle-exclamation"
                                      style={{ fontSize: 21 }}
                                    ></i>
                                    <span style={{ marginLeft: 10 }}>
                                      Your price may be lower at{" "}
                                      <a
                                        href="javascript:void(0)"
                                        onClick={() => goMedications()}
                                      >
                                        a different pharmacy.
                                      </a>
                                    </span>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i
                                      class="fa-solid fa-tag"
                                      style={{ color: "#40DFB9", fontSize: 26 }}
                                    ></i>
                                    <span style={{ marginLeft: 10 }}>
                                      Congratulations! Your pharmacy has the
                                      lowest total price for your drugs in your
                                      area!
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </section>
          <div className="">
            {pharmacistPara && !showMoreInstructions && (
              <div className="row g-0" id="btn-row-back">
                <>
                  <section className=" ">
                    <motion.div className="container">
                      <div className="row ">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <div className="medicine_icon pharma_icon back_icon">
                            <a
                              className="txtDecoration"
                              href="javascript:void(0)"
                              aria-label="Go Back"
                              onClick={() => IMHere(false)}
                            >
                              <i className="fas fa-angle-left"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div role="alert">
                        <div className="container">
                          <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                              <div
                                className="medication-heading instructionHeading"
                                id="pharmacyInstructionsHeading"
                              >
                                <h1>Instructions for pharmacy staff</h1>
                              </div>
                            </div>
                          </div>
                        </div>

                        <motion.div
                          className="row  "
                          initial={{ y: "-70vh", opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          transition={{ duration: 0.5, type: "tween" }}
                        >
                          <div className="col-sm-12 col-md-12 col-lg-12 ">
                            <div className="medications_inner m-0 p-0">
                              <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                  <div className="inst-ff">
                                    <p
                                      style={{
                                        paddingBottom: 10,
                                        fontSize: 16,
                                        color: "rgba(0, 0, 0, 0.7)",
                                      }}
                                    >
                                      {healthSystem} runs value-based care
                                      programs on certain populations of patients
                                      and appreciates you processing this savings
                                      card.  They will be monitoring the savings
                                      your pharmacy brings their members through
                                      these discounts. Thank you for your
                                      partnership.
                                    </p>
                                    <motion.div
                                      className=""
                                      initial={{ scale: 0.5 }}
                                      animate={{ scale: 1 }}
                                      transition={{
                                        duration: 0.5,
                                        delay: 0.3,
                                        type: "tween",
                                      }}
                                    >
                                      {loadingHippoCard ||
                                        loadingRxCard ||
                                        !rxSenseCardInfo ||
                                        !memberInfo ? (
                                        <LoadingComponent />
                                      ) : (
                                        state?.medDrugData?.map(
                                          (element, index) => {
                                            console.log({ element })
                                            return (
                                              <>
                                                {(element?.chain != undefined || element?.bestPrice > 0 || element?.hasInsurance) && (
                                                  <>
                                                    <p className="weight600  mb-4 " style={{ fontSize: 16 }}>
                                                      {index + 1}. For{" "}
                                                      {element?.selectedLabel}
                                                    </p>
                                                    <p className="mt-3 mb-4 pharmac_inst_h4_mobile font-weig-4" style={{ fontSize: 16 }}>
                                                      {element?.hasInsurance
                                                        ? "Use with insurance"
                                                        : "With RxLink Offer"}
                                                    </p>
                                                    <motion.div
                                                      className="col-12 col-sm-12 col-md-12 col-lg-12"
                                                      initial={{ scaleX: 0 }}
                                                      animate={{
                                                        opacity: 1,
                                                        scaleX: 1,
                                                      }}
                                                      transition={{
                                                        duration: 0.8,
                                                        type: "tween",
                                                        delay: 0.4,
                                                      }}
                                                    >
                                                      <motion.div
                                                        className="card_info_new "
                                                      >
                                                        <div className="row">
                                                          <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
                                                            <p className="webView text-start" style={{ fontSize: 20, fontWeight: "bold", textTransform: "capitalize" }}>
                                                              {
                                                                element
                                                                  ?.medicationAPIRes
                                                                  ?.search_name
                                                              }
                                                            </p>
                                                            <p className="mobileView text-start" style={{ fontSize: 16, fontWeight: "bold", textTransform: "capitalize" }}>
                                                              {
                                                                element
                                                                  ?.medicationAPIRes
                                                                  ?.search_name
                                                              }
                                                            </p>
                                                            <p className="mt-2 webView">
                                                              {
                                                                element?.selectedDosage
                                                              }
                                                              {element?.selectedDosage &&
                                                                ", "}
                                                              {element?.selectedQty}{" "}
                                                              {element?.selectedForm}
                                                            </p>
                                                            <p className="mt-2 mobileView text-start">
                                                              {
                                                                element?.selectedDosage
                                                              }
                                                              {element?.selectedDosage &&
                                                                ", "}
                                                              {element?.selectedQty}{" "}
                                                              {element?.selectedForm}
                                                            </p>
                                                          </div>

                                                          <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
                                                            <div className="card_info_new_right">
                                                              {element?.selectedDrug
                                                                ?.connectiveRxOffer
                                                                ?.hasCard != 1 &&
                                                                (element?.bestPrice > 0 || element?.hasInsurance) ? (
                                                                <span className="mt-2 card_info__" style={{ fontSize: 20, fontWeight: "bold" }}>
                                                                  <b>$&nbsp;</b>
                                                                  {element?.bestPrice?.toLocaleString(
                                                                    undefined,
                                                                    {
                                                                      minimumFractionDigits: 2,
                                                                      maximumFractionDigits: 2,
                                                                    }
                                                                  )}
                                                                </span>
                                                              ) : element
                                                                ?.selectedDrug
                                                                ?.connectiveRxOffer
                                                                ?.hasCard == 1 ? (
                                                                <span className="mt-2 card_info__" style={{ fontSize: 20, fontWeight: "bold" }}>
                                                                  <b>$&nbsp;</b>
                                                                  {parseFloat(
                                                                    element
                                                                      ?.selectedDrug
                                                                      ?.connectiveRxOffer
                                                                      ?.price
                                                                  )?.toLocaleString(
                                                                    undefined,
                                                                    {
                                                                      minimumFractionDigits: 2,
                                                                      maximumFractionDigits: 2,
                                                                    }
                                                                  )}
                                                                </span>
                                                              ) : (
                                                                <span className="mt-2 card_info__" style={{ fontSize: 20, fontWeight: "bold" }}>
                                                                  ---
                                                                </span>
                                                              )}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </motion.div>
                                                      <div className="car_info_bottom_new">
                                                        <div className="row">
                                                          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                            <div className="couponTable">
                                                              <div className="row">
                                                                <div className="col-6 col-sm-3 col-md-3 col-lg-3">
                                                                  <div className="webView">
                                                                    <label>BIN</label>
                                                                    <p className="black-text opacity_1" style={{ fontSize: 16, fontWeight: "bold" }}>
                                                                      {getCardDetail(
                                                                        "BIN",
                                                                        element,
                                                                        rxSenseCardInfo,
                                                                        memberInfo,
                                                                        connectiveCards
                                                                      )}
                                                                    </p>
                                                                  </div>
                                                                  <div className="mobileView">
                                                                    <div className="row">
                                                                      <div className="col-sm-12">
                                                                        <label>
                                                                          BIN
                                                                        </label>
                                                                      </div>
                                                                    </div>
                                                                    <div className="row">
                                                                      <div className="col-sm-12">
                                                                        <label className="black-text insrance_card_bottom_label_black opacity_1">
                                                                          {getCardDetail(
                                                                            "BIN",
                                                                            element,
                                                                            rxSenseCardInfo,
                                                                            memberInfo,
                                                                            connectiveCards
                                                                          )}
                                                                        </label>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className="col-6 col-sm-3 col-md-3 col-lg-3">
                                                                  <div className="webView">
                                                                    <label>PCN</label>
                                                                    <p className="black-text opacity_1" style={{ fontSize: 16, fontWeight: "bold" }}>
                                                                      {" "}
                                                                      {getCardDetail(
                                                                        "PCN",
                                                                        element,
                                                                        rxSenseCardInfo,
                                                                        memberInfo,
                                                                        connectiveCards
                                                                      )}
                                                                    </p>
                                                                  </div>
                                                                  <div className="mobileView">
                                                                    <div className="row">
                                                                      <div className="col-sm-12">
                                                                        <label>
                                                                          PCN
                                                                        </label>
                                                                      </div>
                                                                    </div>
                                                                    <div className="row">
                                                                      <div className="col-sm-12">
                                                                        <label className="black-text insrance_card_bottom_label_black opacity_1">
                                                                          {getCardDetail(
                                                                            "PCN",
                                                                            element,
                                                                            rxSenseCardInfo,
                                                                            memberInfo,
                                                                            connectiveCards
                                                                          )}
                                                                        </label>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className="col-6 col-sm-3 col-md-3 col-lg-3">
                                                                  <div className="webView">
                                                                    <label>
                                                                      GROUP
                                                                    </label>
                                                                    <p className="black-text opacity_1" style={{ fontSize: 16, fontWeight: "bold" }}>
                                                                      {" "}
                                                                      {getCardDetail(
                                                                        "GROUP_ID",
                                                                        element,
                                                                        rxSenseCardInfo,
                                                                        memberInfo,
                                                                        connectiveCards
                                                                      )}
                                                                    </p>
                                                                  </div>
                                                                  <div className="mobileView">
                                                                    <div className="row">
                                                                      <div className="col-sm-12">
                                                                        <label>
                                                                          GROUP
                                                                        </label>
                                                                      </div>
                                                                    </div>
                                                                    <div className="row">
                                                                      <div className="col-sm-12">
                                                                        <label className="black-text insrance_card_bottom_label_black opacity_1">
                                                                          {getCardDetail(
                                                                            "GROUP_ID",
                                                                            element,
                                                                            rxSenseCardInfo,
                                                                            memberInfo,
                                                                            connectiveCards
                                                                          )}
                                                                        </label>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className="col-6 col-sm-3 col-md-3 col-lg-3 ">
                                                                  <div className="webView">
                                                                    <label>ID</label>
                                                                    <p className="black-text opacity_1" style={{ fontSize: 16, fontWeight: "bold" }}>
                                                                      {" "}
                                                                      {getCardDetail(
                                                                        "MEMBER_ID",
                                                                        element,
                                                                        rxSenseCardInfo,
                                                                        memberInfo,
                                                                        connectiveCards
                                                                      )}
                                                                    </p>
                                                                  </div>
                                                                  <div className="mobileView">
                                                                    <div className="row">
                                                                      <div className="col-sm-12">
                                                                        <label>
                                                                          ID
                                                                        </label>
                                                                      </div>
                                                                    </div>
                                                                    <div className="row">
                                                                      <div className="col-sm-12">
                                                                        <label className="black-text insrance_card_bottom_label_black opacity_1">
                                                                          {getCardDetail(
                                                                            "MEMBER_ID",
                                                                            element,
                                                                            rxSenseCardInfo,
                                                                            memberInfo,
                                                                            connectiveCards
                                                                          )}
                                                                        </label>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </motion.div>
                                                  </>
                                                )}
                                              </>
                                            );
                                          }
                                        )
                                      )}
                                    </motion.div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </motion.div>
                      </div>
                    </motion.div>
                  </section>
                </>
              </div>
            )}
          </div>

          <div className="">
            {showMoreInstructions && !pharmacistPara && (
              <div className="container">
                <div className="webView">
                  <div className="row ">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="medicine_icon pharma_icon back_icon">
                        <a
                          className="txtDecoration"
                          href="javascript:void(0)"
                          aria-label="Go Back"
                          onClick={() => medications()}
                        >
                          <i className="fas fa-angle-left"></i>
                        </a>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="medicine_icon medicine_icon_m">
                        <div
                          id="dropdownMenuButton1"
                          className="img-fluid pointer"
                          type="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRight"
                          aria-controls="offcanvasRight"
                        >
                          <a href="javascript:void(0)">
                            <img
                              whileTap={{
                                scale: 0.95,
                                transition: { duration: 0.1 },
                              }}
                              src={avatar}
                              alt="Account"
                            ></img>
                          </a>
                        </div>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a
                            href="javascript:void(0)"
                            className="dropdown-item item-1"
                            onClick={() => logout()}
                          >
                            Logout
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {state && (
                  <div className="webView showDivonWeb">
                    <div className="row ">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="medication-heading">
                          <h1>FAQ's about RxLink Coupons</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {state && (
                  <div className="row coupon_main">
                    <motion.div
                      className="col-sm-12 col-md-12 col-lg-9 showDivonWeb"
                      initial={{ y: -50 }}
                      animate={{ y: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <motion.div className="medications_inner medications_inner_coupon  dlr-Sup">
                        <div className="row">
                          {
                            <div className="col-sm-12 col-md-12 col-lg-12">
                              <FAQ />
                            </div>
                          }
                        </div>
                      </motion.div>
                    </motion.div>
                    <div className="col-sm-12 col-md-12 col-lg-3 webView showDivonWeb">
                      <div className="medications_inner medications_inner_coupon dlr-Sup ">
                        <div className={"row"}>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                            <motion.div
                              className="row"
                              initial={{ marginTop: "-0.1rem" }}
                              animate={{ marginTop: "-12px" }}
                              transition={{ duration: 0.5, delay: 0.18 }}
                            >
                              <motion.div
                                className="col-6 col-sm-6 col-md-12 col-lg-12"
                                initial={{ y: -50 }}
                                animate={{ y: 0 }}
                                transition={{ duration: 0.6 }}
                              >
                                <button
                                  className="btn_success"
                                  aria-live="polite"
                                  aria-label="show coupons"
                                  role="button"
                                  onClick={() => {
                                    if (showMoreInstructions) {
                                      setShowMoreInstructions(false);
                                      setPharmacistPara(false);
                                    } else {
                                      openSMSpopup(true);
                                    }
                                  }}
                                >
                                  Show Coupons
                                </button>
                              </motion.div>
                              <motion.div
                                className="col-6 col-sm-6 col-md-12 col-lg-12"
                                initial={{ y: -50 }}
                                animate={{ y: 0 }}
                                transition={{ duration: 0.5 }}
                              >
                                <button
                                  className="btn_success"
                                  onClick={() => {
                                    if (showMoreInstructions) {
                                      setShowMoreInstructions(false);
                                      setPharmacistPara(true);
                                    } else {
                                      openEmailPopup(true);
                                    }
                                  }}
                                >
                                  Show Pharmacy Instructions
                                </button>
                              </motion.div>
                            </motion.div>
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12 ">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          <Modal
            show={isVisibleSMSModal}
            onHide={() => setIsVisibleSMSModal(false)}
            keyboard={false}
            centered
          >
            <Modal.Body>
              <section>
                <div className="container">
                  <form id="loginID">
                    <div className="forget_box">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                          <p>Are you sure you want to active SMS</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-4 col-md-4 col-lg-4">
                          <button
                            type="button"
                            className="btn btn-primary forget_btn"
                            onClick={() => sendSMS(true)}
                          >
                            Yes
                          </button>
                        </div>
                        <div className="col-sm-4 col-md-4 col-lg-4">
                          <button
                            type="button"
                            className="btn btn-primary forget_btn"
                            onClick={() => setIsVisibleSMSModal(false)}
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </section>
            </Modal.Body>
          </Modal>

          <Modal
            show={isComingSoon}
            onHide={() => setIsComingSoon(false)}
            keyboard={false}
            centered
          >
            <Modal.Body>
              <section>
                <div className="container">
                  <form id="loginID">
                    <div className="forget_box row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <p className="text-center">Coming soon!</p>
                      </div>
                      <div className="row">
                        <div className="center">
                          <button
                            type="button"
                            className="btn btn-primary commingSoonBtn"
                            onClick={() => setIsComingSoon(false)}
                          >
                            OK
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </section>
            </Modal.Body>
          </Modal>

          <SendSMSOrEmailModalWeb
            isOpen={isSMS || isEmail || isEmailOrSMSSuccess}
            formik={formik}
            handleClose={() => closeSMSOrEmailPopup(false)}
            isSMS={isSMS}
            sendEmail={() => sendEmail(true)}
            sendSMS={formik.handleSubmit}
            validateEmail={validateEmail}
            emailError={emailError}
            hasSuccess={isEmailOrSMSSuccess}
            termsHandler={termsHandler}
            termsError={termsError}
            isValidEmailModal={isValidEmailModal}
            isEmail={isEmail}
          />
        </>
      )}
    </>
  );
};

export default CouponCard;
